<template>
  <div>
      <el-form ref="form" label-width="100px" :model="form" label-position="left">
        <el-form-item label="站点配图">
          <el-upload
            ref="upload"
            :disabled="utils.userReadOnly(user)"
            v-model="form.file"
            :data="form"
            :headers="headers"
            accept="image/png"
            action="/api/companies/uploadFile"
            :on-change="onChange"
            :on-success="onSuccess"
            :on-preview="onPreview"
            :on-remove="onRemove"
            :file-list="fileList"
            list-type="picture-card"
          >
            <el-button slot="trigger" size="mini" type="primary" :disabled="utils.userReadOnly(user)">选取图片</el-button>
            <div slot="tip" class="el-upload__tip">只能上传 png 格式的图片，且文件大小不能超过 2 MB</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { getToken } from '@/utils/auth';
import siteApi from "@/api/site";
import bizApi from "@/api/business";

export default {
  computed: {
    ...mapGetters(['user']),
    headers: function () {
      const token = getToken();
      return { 
        Authorization: token
      };
    }
  },
  props: {
    siteId: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      loading: false,
      utils: $utils,
      height: 0,
      fileList: [],
      file: null,
      form: {
        file: "",
        id: ""
      },
      siteImages: [],
      dialogVisible: false,
      detail: {},
    }
  },
  created () {
    console.log("site-images");
    this.height = document.body.scrollHeight - 66;

    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;
        this.fileList = [];
        const res = await siteApi.detail(this.siteId);

        let { siteId, siteImages } = this.detail = res;
        if (siteImages) {
          this.siteImages = siteImages.split(",");
        }

        for (const id of this.siteImages) {
          const name = `site-image-${siteId}-${id}`;
          const file = {
            name, url: `${process.env.VUE_APP_BASE_URL}/wxapp/upload/${name}.png`
          };
          this.fileList.push(file);
        }
      } finally {
        this.loading = false;
      }
    },
    onPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
    },
    onChange (file, fileList) {
      const dt = Date.now();
      this.form.id = `site-image-${this.siteId}-${dt}`;
      this.siteImages.push(dt);
      if (file.status === "ready") {
        if (file.size / 1024 > 2000) {
          this.$message.warning("文件大小不能超过 2 MB，请重新选择");
          fileList.pop();
          this.fileList = fileList;
          return;
        }
        if (fileList.length > 1) {
          this.fileList = [fileList.pop()];
        }

        this.file = file;
      }
    },
    async onRemove (file, fileList) {

      const name = file.name;
      let code = name.split("-");
      code = code[code.length - 1];
      const res = await bizApi.delFile({ fileName: `${file.name}.png` });

      if (res === "SUCCESS") {
        const index = this.siteImages.indexOf(code);
        this.siteImages.splice(index, 1);

        this.$message.success(`站点配图（文件名${name}）删除成功！`);
        this.onSuccess();
      } else {
        this.$message.success(`站点配图（文件名${name}）删除失败！`);
        this.getDatas();
      }
    },
    async onSuccess () {
      this.loading = true;

      this.detail.siteImages = this.siteImages.join();
      await siteApi.edit(this.detail);

      this.getDatas();
    }
  }
}
</script>