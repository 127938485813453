var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "100px",
            model: _vm.form,
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "站点配图" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    disabled: _vm.utils.userReadOnly(_vm.user),
                    data: _vm.form,
                    headers: _vm.headers,
                    accept: "image/png",
                    action: "/api/companies/uploadFile",
                    "on-change": _vm.onChange,
                    "on-success": _vm.onSuccess,
                    "on-preview": _vm.onPreview,
                    "on-remove": _vm.onRemove,
                    "file-list": _vm.fileList,
                    "list-type": "picture-card",
                  },
                  model: {
                    value: _vm.form.file,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "file", $$v)
                    },
                    expression: "form.file",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: "mini",
                        type: "primary",
                        disabled: _vm.utils.userReadOnly(_vm.user),
                      },
                      slot: "trigger",
                    },
                    [_vm._v("选取图片")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("只能上传 png 格式的图片，且文件大小不能超过 2 MB")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }