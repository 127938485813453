<template>
  <div class="big-data-container">
    <div class="content">
      <div class="content-btn-box" v-if="isDetail && tabName === 'detail'">
        <el-button size="mini" type="primary" icon="el-icon-edit" :disabled="utils.userReadOnly(user)" @click="onEdit">编辑站点</el-button>
        <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" :disabled="utils.userReadOnly(user)" @click="onDelSite">删除站点</el-button>
      </div>
      <div class="content-input-box" :style="`height: 100%;`">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="getGotoObj()">站点管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="height: 100%;" v-loading="loading">
          <el-tabs class="device-detail-tabs" tab-position="left" v-model="tabName"
            @tab-click="onTabClick">
            <el-tab-pane name="detail" label="基本信息" style="height: 100%">
              <detail-edit class="m-l-10" :data="form" v-if="tabName === 'detail'" ref="basic"
                :is-add="isAdd" :is-detail="isDetail" @close="onCloseEdit" />
            </el-tab-pane>
            <el-tab-pane name="images" label="站点配图" style="height: 100%">
              <images ref="images" class="m-l-10" v-if="tabName === 'images'" :site-id="siteId" :biz-id="bizId" />
            </el-tab-pane>
            <el-tab-pane name="devices" label="下属设备" style="height: 100%">
              <device-configs class="m-l-10" v-if="tabName === 'devices'" ref="devices" :site-id="siteId" :biz-id="bizId" :site-name="form.siteName" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import bizApi from "@/api/business";
import siteApi from "@/api/site";

import DetailEdit from "./detail-edit";
import images from "./images";
import UserConfig from "./user-config";
import DeviceConfigs from "./device-configs";
// import datas from "./datas";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    DetailEdit,
    images,
    UserConfig,
    DeviceConfigs,
    // datas
  },
  data () {
    return {
      loading: false,
      utils: $utils,
      tabName: "detail",
      isDetail: true,
      isAdd: false,
      company: null,
      title: "编辑站点",
      bisId: "",
      siteId: "",
      sitePkId: "",
      form: {
        city: "",
        companyId: null,
        companyName: null,
        location: window.LngLat,
        longitude: "",
        latitude: "",
        number: 0,
        siteId: "",
        siteName: "",
        mobileName: "",
        mobile: "",
        regionName: ""
      },
    }
  },
  async created () {
    console.log("site-detail-index");
    this.siteId = this.$route.params.siteId;
    this.bizId = this.$route.query.bizId;

    await this.getDatas();
  },
  methods: {
    async getDatas () {
      await this.getSiteData();
    },
    async getSiteData () {

      try {
        this.loading = true;
        const res = await siteApi.detail(this.siteId);

        if (!res) {
          this.$message({
            showClose: true,
            type: "error",
            message: `找不到站点（站点ID：${this.siteId}），请确认站点ID没有错误`
          });
          this.$router.push({
            path: "/site"
          });
          return;
        }

        this.bizId = res.businessId = res.companyId;

        const route = Object.assign({}, this.$route, { title: res.siteName });
        this.$store.dispatch('tagsView/updateVisitedView', route);

        res.location = [ res.longitude, res.latitude ];
        this.sitePkId = res.id;

        try {
          await this.getBizData();
          res.tenantId = this.company.tenantId;
          res.companyName = this.company.companyName;
        } catch (err) {
          this.$message.warning(`当前站点（${res.siteName}）的所属公司获得失败，请确认公司没有配置没有错误`);
        } finally {
          this.form = res;
          this.title = this.form.siteName;
        }
      } finally {
        this.loading = false;
      }
    },
    async getBizData () {
      const res = await bizApi.detail(this.bizId);
      this.company = res;
    },
    getGotoObj () {
      return {
        path: `/site`
      }
    },
    onEdit () {
      this.title = "编辑站点";
      this.isDetail = false;
    },
    onCloseEdit (site) {
      this.isDetail = true;
      this.bizId = site.companyId;
      this.title = site.siteName;
      this.getSiteData();
    },
    onTabClick () {
      if (this.tabName === "detail") {
        const data = Object.assign({}, this.form);
        this.onCloseEdit(data);
      }
    },
    async onDel () {
      try {
        await siteApi.del(this.sitePkId);
        this.$message.success(`站点(${this.siteName})删除成功`);
        setTimeout(() => {
          this.$router.push({
            path: `/site`
          })
        }, 500);
      } catch (err) {
        console.log(err);
        this.$message.error(`站点(${this.siteName})删除成功`);
      } finally {
      }
    },
    onDelSite () {
      $utils.deleteConfirm(this, "站点", this.title);
    }
  }
}
</script>