var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", "label-position": "left" },
        },
        [
          _c("biz-edit", {
            ref: "bizEdit",
            attrs: {
              props: ["tenantId", "businessId"],
              "data-obj": _vm.dataObj,
              "is-admin": _vm.isAdmin,
              "is-add": !_vm.isAdmin,
            },
            on: { "select-company": _vm.onSelecCompany },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          style: `margin-left:80px; height:${_vm.height}px;`,
        },
        [
          _c("el-transfer", {
            staticStyle: { height: "100%" },
            attrs: {
              "filter-placeholder": "请输入用户名称",
              filterable: "",
              data: _vm.data,
              titles: [`待选择用户`, "已选择用户"],
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "m-t-10 right-btns" },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-circle-check",
                type: "primary",
                size: "small",
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }