var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", "label-position": "left" },
        },
        [
          _c("biz-edit", {
            ref: "bizEdit",
            attrs: {
              "data-obj": _vm.object,
              "is-detail": false,
              "is-add": true,
              "is-admin": _vm.user.tenantId === 0,
            },
            on: { "select-company": _vm.onSelecCompany },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          style: `margin-left:80px; height:${_vm.height}px;`,
        },
        [
          _c("el-transfer", {
            staticStyle: { height: "100%" },
            attrs: {
              "filter-placeholder": "请输入设备编号",
              filterable: "",
              data: _vm.data,
              titles: [`待选择设备`, "已选择设备"],
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns m-t-10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-refresh-left" },
              on: { click: _vm.onClose },
            },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                icon: "el-icon-circle-check",
                disabled: _vm.utils.userReadOnly(_vm.user),
                type: "primary",
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }