<template>
  <div>
    <el-form label-width="80px" ref="form" label-position="left">
      <!-- 因为非超级管理员在载入时，直接通过 created 载入数据，所以这里直接设置 is-add 和 !is-admin 关联 -->
      <biz-edit ref="bizEdit" :props="[ 'tenantId', 'businessId' ]" :data-obj="dataObj"
        :is-admin="isAdmin" @select-company="onSelecCompany" :is-add="!isAdmin" />
    </el-form>
    <div :style="`margin-left:80px; height:${height}px;`" v-loading="loading">
      <el-transfer filter-placeholder="请输入用户名称" filterable v-model="value" :data="data"  :titles="[ `待选择用户`, '已选择用户' ]" style="height:100%;"></el-transfer>
    </div>
    <div class="m-t-10 right-btns">
      <el-button icon="el-icon-circle-check" type="primary" size="small" @click="onSubmit">提交</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import userSiteRelationApi from '@/api/user-site-relation';
import userApi from "@/api/user";

import BizEdit from "@/components/business/edit";

export default {
  components: {
    BizEdit
  },
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    siteId: {
      type: String,
      default: ""
    },
    bizId: {
      type: String,
      default: ""
    },
    company: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      loading: false,
      isAdmin: false,
      selectCompany: null,
      data: [],
      value: [],
      users: {},
      allUser: {},
      height: 0,
      protoVal: [],
      dataObj: {
        tenantId: "",
        businessId: ""
      }
    }
  },
  created () {
    console.log("user-config");
    this.height = document.body.scrollHeight - (this.user.tenantId === 0 ? 294 : 230);
    this.isAdmin = this.user.tenantId === 0;

    if (!this.isAdmin) {
      this.dataObj.tenantId = this.user.tenantId;
      this.dataObj.businessId = this.user.businessId;
    }

    this.getDatas();
  },
  methods: {
    async getDatas () {
      const res = await userSiteRelationApi.list({
        site: this.siteId,
        siteId: this.siteId
      });

      const users = {};
      const value = res.data.map((obj) => {
        users[obj.userId] = obj;
        return obj.userId
      });

      this.users = users;
      this.value = value;
      this.protoVal = [].concat(value);
    },
    onSelecCompany (company) {
      if (company) {
        this.selectCompany = company;
        this.loadUsers();
      } else {
        this.data = [];
      }
    },
    async loadUsers () {
      try {
        this.loading = true;
        const res = await userApi.list({
          page: 0,
          size: 9999999,
          businessId: this.selectCompany.id
        });

        const data = res.data.map((user) => {
          return {
            label: user.username,
            key: user.id
          }
        });

        this.data = data;
      } catch (err) {
        this.$message.err(err.response.data.message);
        this.data = [];
      } finally {
        this.loading = false;
      }
    },
    async onSubmit () {
      try {
        this.loading = true;
        // 1. 判断有哪些新增
        const requests = [], params = [];
        let addCnts = 0;
        for (const val of this.value) {
          if (this.protoVal.indexOf(val) < 0) {
            addCnts++;
            const param = {
              userId: val,
              site: this.siteId
            };
            params.push(param);
            requests.push(userSiteRelationApi.add(param));
          }
        }

        // 2. 判断有哪些删除
        for (const val of this.protoVal) {
          if (this.value.indexOf(val) < 0) {
            const user = this.users[val];
            requests.push(userSiteRelationApi.del(user.id));
          }
        }

        const reses = await Promise.all(requests);
        
        for (let i = 0; i < addCnts; i++) {
          const res = reses[i]; // 返回的 id
          const param = params[i]; // 参数
          let user = this.users[param.userId];
          if (!user) {
            user = this.users[param.userId] = param;
          }
          user.id = res;
        }

        this.protoVal = [].concat(this.value);

        this.$message.success("站点关联用户配置修改成功");
      } catch (err) {
        console.log(err);
        this.$message.error("站点关联用户配置修改失败");
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
<style scoped>
::v-deep .el-transfer-panel__body {
  height: 100% !important;
}

::v-deep .el-transfer-panel__list {
  height: calc(100% - 104px) !important;
}
</style>