<template>
  <div v-loading="loading">
    <el-form label-width="80px" ref="form" label-position="left">
      <biz-edit ref="bizEdit" :data-obj="object" :is-detail="false" :is-add="true" :is-admin="user.tenantId === 0" @select-company="onSelecCompany" />
    </el-form>
    <div :style="`margin-left:80px; height:${height}px;`" v-loading="loading">
      <el-transfer filter-placeholder="请输入设备编号" filterable v-model="value" :data="data"  :titles="[ `待选择设备`, '已选择设备' ]" style="height:100%;"></el-transfer>
    </div>
    <div class="right-btns m-t-10">
      <el-button size="mini" icon="el-icon-refresh-left"  @click="onClose">返回</el-button>
      <el-button size="mini" icon="el-icon-circle-check" :disabled="utils.userReadOnly(user)" type="primary" @click="onSubmit">提交</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import DevicePlateApi from '@/api/470/DevicePlate';

import BizEdit from "@/components/business/edit";

export default {
  components: {
    BizEdit
  },
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    siteId: {
      type: String,
      default: "",
    },
    siteName: {
      type: String,
      default:  ""
    },
  },
  data () {
    return {
      loading: false,
      object: {
        tenantId: "",
        businessId: ""
      },
      utils: $utils,
      company: {},
      siteId: "",
      data: [],
      value: [],
      map: [],
      users: {},
      allUser: {},
      height: 0,
      protoVal: [],
    }
  },
  created () {
    console.log("site-add-device");
    this.height = document.body.scrollHeight - (this.user.tenantId === 0 ? 294 : 230);

    if (this.user.tenantId !== 0) {
      this.object.tenantId = this.user.tenantId;
      this.object.businessId = this.user.businessId;
    }
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;
        if (this.company);
        const reses = await Promise.all([
          this.getDevices(),
          this.getDevices(this.siteId)
        ]);

        const list = reses[0], map = {};
        this.data = list.map((obj) => {
          map[obj.id] = obj;
          return {
            label: `${obj.information}（设备编号：${obj.code}）`,
            key: obj.id
          };
        });
        this.value = reses[1].map((obj) => {
          return obj.id;
        });
        this.protoVal = [].concat(this.value);
        this.map = map;
      } finally {
        this.loading = false;
      }

    },
    async getDevices(siteId) {
      const params = {
        page: 1,
        businessId: this.company.id,
        size: 9999999,
        siteId
      };
      const res = await DevicePlateApi.list(params);
      return res.data;
    },
    async onSelecCompany (company) {
      this.company = company;
      if (company) {
        this.data = [];
        this.getDatas();
      }
    },
    onClose () {
      this.$emit("close");
    },
    async onSubmit () {
      try {
        this.loading = true;

        // 1. 判断有哪些新增
        const requests = [];
        for (const val of this.value) {
          if (this.protoVal.indexOf(val) < 0) {
            const device = this.map[val];
            device.site = this.siteId;
            device.siteName = this.siteName;
            requests.push(editDevices(device.id, device));
          }
        }
        // 2. 判断有哪些删除
        for (const val of this.protoVal) {
          if (this.value.indexOf(val) < 0) {
            const device = this.map[val];
            delete device.site;
            delete device.siteName;
            requests.push(editDevices(device.id, device));
          }
        }

        if (requests.length > 0) {
          await Promise.all(requests);
          this.protoVal = [].concat(this.value);

          this.$message.success("站点下属设备配置修改成功");
        }
      } catch (err) {
        console.log(err);
        this.$message.error("站点下属设备配置修改失败");
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
<style scoped>
::v-deep .el-transfer-panel__body {
  height: 100% !important;
}

::v-deep .el-transfer-panel__list {
  height: calc(100% - 104px) !important;
}
</style>